import Link from 'next/link'
import { ZoomImage } from './ZoomImage/ZoomImage'

export function LinkTag({ children, node }) {
  if (isRelativeUrl(node?.properties?.href)) {
    return <Link {...node?.properties}>{children}</Link>
  }

  if (isImageUrl(node?.properties?.href)) {
    return <ZoomImage link={node?.properties?.href}>{children}</ZoomImage>
  }

  return <a {...node?.properties}>{children}</a>
}

function isRelativeUrl(url) {
  const relativeStartsWith = [
    '/',
    './',
    '../',
    process.env.NEXT_PUBLIC_SITE_URI,
  ]

  return relativeStartsWith.some((s) => url.startsWith(s))
}

function isImageUrl(url) {
  const urlInLowerCase = url.toLowerCase()
  return (
    urlInLowerCase.endsWith('.png') ||
    urlInLowerCase.endsWith('.jpg') ||
    urlInLowerCase.endsWith('.jpeg')
  )
}
