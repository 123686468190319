import { PropsWithChildren } from 'react'
import { Container } from 'routes/components/Layout'
import { BookCalendlyButton } from 'routes/components/Shared'
import CTAImgMob1x from './cta-img-mob@1x.png'
import CTAImgMob2x from './cta-img-mob@2x.png'
import CTAImgDesk1x from './cta-img-desk@1x.png'
import CTAImgDesk2x from './cta-img-desk@2x.png'
import clsx from 'clsx'
import classes from './FooterCTA.module.scss'

export const FooterCTA = ({ children }: PropsWithChildren) => {
  return (
    <section className={clsx('flex-column', classes.section)}>
      <Container>
        <div className={classes.wrapper}>
          <div className={classes.heading}>
            <h2 className={classes.title}>
              Supercharge your product integrations today
            </h2>
            {children ? (
              <>{children}</>
            ) : (
              <BookCalendlyButton
                className={clsx('page__button', classes.button)}
                category='secondary'
              >
                Schedule a personalized demo
              </BookCalendlyButton>
            )}
          </div>
          <picture className={classes.image}>
            <source
              media='(min-resolution: 2dppx) and (min-width: 768px)'
              srcSet={CTAImgDesk2x.src}
            />
            <source media='(min-width: 768px)' srcSet={CTAImgDesk1x.src} />
            <source
              media='(min-resolution: 2dppx) and (max-width: 767px)'
              srcSet={CTAImgMob2x.src}
            />
            <source media='(max-width: 767px)' srcSet={CTAImgMob1x.src} />
            <img
              src={CTAImgMob1x.src}
              alt=''
              aria-hidden='true'
              height={579}
              width={1194}
              loading='lazy'
            />
          </picture>
          <div className={classes.glow} />
        </div>
      </Container>
    </section>
  )
}
