import { createContext, useContext, useMemo, useState } from 'react'
import { BookCalendlyContextInterface } from './types'

const BookCalendlyContext = createContext<BookCalendlyContextInterface>({
  showPopup: false,
  togglePopup: () => {},
  eventData: null,
  setEventData: () => {},
})
BookCalendlyContext.displayName = 'BookCalendlyContext'

export const BookCalendlyProvider = ({ children }) => {
  const [showPopup, setShowPopup] = useState(false)
  const [eventData, setEventData] = useState()

  const togglePopup = () => setShowPopup((v) => !v)

  const value = useMemo(
    () => ({ showPopup, togglePopup, eventData, setEventData }),
    [showPopup, togglePopup, eventData, setEventData],
  )

  return (
    <BookCalendlyContext.Provider value={value}>
      {children}
    </BookCalendlyContext.Provider>
  )
}
export function useBookCalendly() {
  return useContext(BookCalendlyContext)
}
