import classes from './ArticlePublishDate.module.css'
import clsx from 'clsx'

export function ArticlePublishDate({
  children,
  textOnly,
  className,
}: {
  children: string
  textOnly?: boolean
  className?: string
}) {
  if (!children) return null

  const formattedDate = new Date(children).toLocaleDateString('en-US', {
    dateStyle: 'medium',
  })

  return (
    <span className={clsx(classes.wrapper, className)}>
      {!textOnly && CalendarIcon()}
      <span>{formattedDate}</span>
    </span>
  )
}

function CalendarIcon() {
  return (
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
      className={classes.calendarIcon}
    >
      <g
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      >
        <path d='m19 4h-14c-1.10457 0-2 .89543-2 2v14c0 1.1046.89543 2 2 2h14c1.1046 0 2-.8954 2-2v-14c0-1.10457-.8954-2-2-2z' />
        <path d='m3 10h18' />
        <path d='m16 2v4' />
        <path d='m8 2v4' />
      </g>
    </svg>
  )
}
