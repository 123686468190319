import React, {
  createContext,
  MutableRefObject,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDebounceCallback } from 'usehooks-ts'
import { HEADERS_IN_TOC } from 'routes/constants'
import { useThrottleCallback } from 'hooks/useThrottleCallback'

type HeaderDataType = {
  id: string
  offsetTop: number
}

// @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
const ArticleTocContext = createContext<string>(null)

export const useArticleActiveHeaderId = () => useContext(ArticleTocContext)

export function ArticleActiveHeaderIdProvider({ children }) {
  const [visibleHeaderId, setVisibleHeaderId] = useState(null)
  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const activeHeaderId = getWindowHashIdOrFallback(visibleHeaderId)

  return (
    <ArticleTocContext.Provider value={activeHeaderId}>
      {/*// @ts-expect-error TS(2322): Type 'Dispatch<SetStateAction<null>>' is not assig... Remove this comment to see the full error message*/}
      <DetectActiveHeaderOnPage onChange={setVisibleHeaderId}>
        {children}
      </DetectActiveHeaderOnPage>
    </ArticleTocContext.Provider>
  )
}

function DetectActiveHeaderOnPage({
  children,
  onChange,
}: PropsWithChildren<{
  onChange: (id: string) => void
}>) {
  const [initialized, setInitialized] = useState(false)
  const headerDataMapRef = useRef<HeaderDataType[]>([])

  const containerElement = getElementHtml()

  const handleLayoutChange = useDebounceCallback(() => {
    if (containerElement) {
      headerDataMapRef.current = getHeadersDataFromContainer()
      setInitialized(true)
    }
  }, 300)

  const checkVisibleHeadersAndUpdateHashDebounced = useDebounceCallback(
    checkVisibleHeadersAndUpdateHash,
    1000,
  )

  const handleScroll = useThrottleCallback(() => {
    // Do not run if headers are not initialized or container is not found
    if (!containerElement || !initialized) return

    const containerHeight = containerElement.clientHeight
    const containerScrollTop = containerElement.scrollTop

    const halfPageYOffset = containerScrollTop + containerHeight * 0.4

    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
    let newActiveHeader: string = null

    headerDataMapRef?.current?.forEach((header) => {
      const headerOffsetTop = header.offsetTop

      if (headerOffsetTop <= halfPageYOffset) {
        newActiveHeader = header.id
      }
    })

    onChange(newActiveHeader)

    checkVisibleHeadersAndUpdateHashDebounced(
      headerDataMapRef,
      containerElement,
    )
  }, 300)

  useEffect(() => {
    if (!containerElement) return () => {}

    const resizeObserver = new ResizeObserver(handleLayoutChange)
    const mutationObserver = new MutationObserver(handleLayoutChange)

    resizeObserver.observe(containerElement)
    mutationObserver.observe(containerElement, {
      attributes: true,
      childList: true,
      subtree: true,
    })

    document.addEventListener('scroll', handleScroll)

    return () => {
      resizeObserver.unobserve(containerElement)
      mutationObserver.disconnect()
      document.removeEventListener('scroll', handleScroll)
    }
  }, [containerElement, handleScroll, handleLayoutChange])

  return children as JSX.Element
}

function getWindowHashIdOrFallback(fallbackHeaderId = ''): string {
  if (typeof window === 'undefined') return fallbackHeaderId
  const hash = window.location.hash.split('#')[1]

  return hash ? hash : fallbackHeaderId
}

function getElementHtml(): HTMLElement {
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'HTMLElement... Remove this comment to see the full error message
  if (typeof document === 'undefined') return null
  // @ts-expect-error TS(2322): Type 'HTMLHtmlElement | null' is not assignable to... Remove this comment to see the full error message
  return document.querySelector('html')
}

function getHeadersDataFromContainer(): HeaderDataType[] {
  const selectors = HEADERS_IN_TOC.map((x) => `${x}[id]`).join(', ')
  return Array.from(
    document.querySelectorAll(selectors) as NodeListOf<HTMLHeadingElement>,
  ).map((header) => ({ id: header.id, offsetTop: header.offsetTop }))
}

function checkVisibleHeadersAndUpdateHash(
  headerDataMapRef: MutableRefObject<HeaderDataType[]>,
  containerElement: HTMLElement,
) {
  const containerHeight = containerElement.clientHeight
  const containerScrollTop = containerElement.scrollTop

  const visibleHeaders: string[] = []

  headerDataMapRef?.current?.forEach((header) => {
    const headerOffsetTop = header.offsetTop

    if (
      containerScrollTop - 50 < headerOffsetTop &&
      headerOffsetTop < containerScrollTop + containerHeight + 50
    ) {
      visibleHeaders.push(header.id)
    }
  })

  // When header with hash is not visible, remove hash from url
  // to switch to default header detection by scroll position
  const hash = window?.location?.hash.slice(1)

  if (hash && !visibleHeaders.includes(hash)) {
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    history?.pushState(null, null, '#')
  }
}
