import React, { PropsWithChildren } from 'react'
import { Container } from 'routes/components/Layout'
import { StyledMarkdownWrapper } from 'routes/components/Shared'
import clsx from 'clsx'
import classes from './Article.module.css'

function ContentRoot({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <Container className={clsx(classes.content, className)}>
      {children}
    </Container>
  )
}

function Content({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <main className={clsx(classes.main, className)}>
      <div className={classes.main_content}>{children}</div>
    </main>
  )
}

function StyledTypography({ children }: PropsWithChildren) {
  return <StyledMarkdownWrapper>{children}</StyledMarkdownWrapper>
}

function Sidebar({
  sticky,
  children,
}: PropsWithChildren<{ sticky?: boolean }>) {
  return (
    <aside className={classes.sidebar}>
      <div className={clsx(sticky && classes.sidebar_content__sticky)}>
        {children}
      </div>
    </aside>
  )
}

export const Article = Object.assign(ContentRoot, {
  Content,
  Sidebar,
  StyledTypography,
})
