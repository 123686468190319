import { PropsWithChildren } from 'react'

import classes from './Highlight.module.css'

export function Highlight({
  children,
  title,
}: PropsWithChildren<{
  title?: string
}>) {
  return (
    <div className={classes.quoteText}>
      <div className={classes.titleWrapper}>
        {title && <p className={classes.title}>{title}</p>}
      </div>
      <div className={classes.quoteBody}>{children}</div>
    </div>
  )
}
