import { visit } from 'unist-util-visit'

// Support <Highlight> component in markdown
//
// Use `remark-directive` to parse a custom components
// and add the necessary data to the node

export function remarkCustomHighlightDirectivePlugin() {
  return function transformer(tree: Node) {
    const visitor = (node) => {
      if (node.name !== 'highlight') return

      const blockHasTitle =
        node.children[0].position.start.line === node.position.start.line
      let title = ''

      if (blockHasTitle) {
        title = node.children[0].children[0].value
        node.children.shift()
      }

      const data = node.data || (node.data = {})

      // Should be the name of the component in markdownArticleComponents
      data.hName = 'Highlight'

      // Props to pass to the component
      data.hProperties = { title }
    }
    visit(tree, ['containerDirective'], visitor)
  }
}
