type QueryType = Record<string, string | string[] | number | null | undefined>

type SafeQueryType = Record<string, string | number>

export function removeEmptyQueries(queries: QueryType) {
  return Object.fromEntries(
    Object.entries(queries).filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, v]) => !isEmptyQueryParam(v),
    ),
  ) as SafeQueryType
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isEmptyQueryParam(param: any) {
  return (
    param === '' || param?.length === 0 || param === undefined || param === null
  )
}

export function queryString(query: QueryType) {
  const searchParams = new URLSearchParams()
  Object.entries(query).forEach(([key, value]) => {
    if (isEmptyQueryParam(value)) {
      searchParams.delete(key)
    } else {
      // @ts-expect-error value always exists
      searchParams.set(key, value.toString())
    }
  })

  return searchParams.toString()
}

export function getUrlWithQuery(url: string, query: QueryType) {
  const newUrl = new URL(url)
  Object.entries(query).forEach(([key, value]) => {
    if (isEmptyQueryParam(value)) {
      newUrl.searchParams.delete(key)
    } else {
      // @ts-expect-error value always exists
      newUrl.searchParams.set(key, value.toString())
    }
  })

  return newUrl.toString()
}
