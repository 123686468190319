import { Link } from 'routes/components/Shared'
import clsx from 'clsx'
import classes from './LogoBlock.module.scss'

export const LogoBlock = ({ className }: { className?: string }) => {
  return (
    <Link className={classes.logo} href='/' target='_self'>
      <img
        width={50}
        height={30}
        loading='eager'
        className={classes.image}
        src='/images/logo--white.svg'
        alt='Integration App logo'
      />

      <span className={clsx(classes.text, className)}>Integration App</span>
    </Link>
  )
}
