import { Fragment, PropsWithChildren } from 'react'
import fonts from 'public/fonts/fontsData'

export function FontsProvider({ children }: PropsWithChildren) {
  return (
    <Fragment>
      <style jsx global>{`
        :root {
          --font-satoshi: ${fonts.satoshi.style.fontFamily};
          --font-clash: ${fonts.clashGrotesk.style.fontFamily};
          --font-logo: ${fonts.logoFont.style.fontFamily};
          --font-fira: ${fonts.firaCode.style.fontFamily};
          --font-digital: ${fonts.digitalNumbers.style.fontFamily};
        }
      `}</style>
      {children}
    </Fragment>
  )
}
