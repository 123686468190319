import { useRef } from 'react'
import { Button } from 'routes/components/Shared'
import { TabType } from './types'
import clsx from 'clsx'
import { useMediaQuery } from 'usehooks-ts'

export const Tabs = ({
  tabsData = [],
  onTabClick,
  activeId,
  className,
}: {
  tabsData: TabType[]
  onTabClick: (tab: string) => void
  activeId?: string
  className?: string
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  const tabsRef = useRef(null)

  const onClick = (tab: string) => {
    const isTabActive = activeId === tab

    if (isTabActive) return

    onTabClick(tab)
  }

  return (
    <ul ref={tabsRef} className={className}>
      {tabsData.map(({ id, title, mobileTitle, icon }) => (
        <li key={id} className='tabs_item'>
          <Button
            className={clsx('tabs_btn', id === activeId && 'tabs_btn__active')}
            onClick={() => onClick(id)}
          >
            {icon && <img src={icon.src} alt={title} className='tabs_icon' />}
            <span className='tabs_caption'>
              {isMobile && mobileTitle ? mobileTitle : title}
            </span>
          </Button>
        </li>
      ))}
    </ul>
  )
}
