import React, { AnchorHTMLAttributes } from 'react'
import NextLink from 'next/link'
import clsx from 'clsx'

export type LinkType =
  | 'primary'
  | 'secondary'
  | 'line'
  | 'noLine'
  | 'lineGradient'
  | 'noLineGradient'

export const Link = ({
  children,
  type,
  className,
  href,
  target,
  ...attrs
}: AnchorHTMLAttributes<HTMLAnchorElement> & {
  type?: LinkType
}) => {
  const types = {
    primary: 'link--primary',
    secondary: 'link--secondary',
    line: 'link--line',
    noLine: 'link--no-line',
    lineGradient: 'link--line-gradient',
    noLineGradient: 'link--no-line-gradient',
  }

  const linkClassname = clsx('link', type && types[type], className)

  if (!href) return <a className={linkClassname}>{children}</a>

  if (target === '_blank' || href?.startsWith('http')) {
    return (
      <a
        className={linkClassname}
        href={href}
        target={target ? target : undefined}
        {...attrs}
      >
        <span className='link__content'>{children}</span>
      </a>
    )
  }

  return (
    <NextLink
      href={href}
      className={linkClassname}
      target={target ? target : undefined}
      {...attrs}
    >
      <span className='link__content'>{children}</span>
    </NextLink>
  )
}
