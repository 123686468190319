import { forwardRef, Ref, InputHTMLAttributes } from 'react'
import { TbSearch } from 'react-icons/tb'
import clsx from 'clsx'
import classes from './Input.module.scss'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string
  placeholder?: string
  hasIcon?: boolean
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ hasIcon, className, ...props }, ref: Ref<HTMLInputElement>) => {
    return (
      <div
        className={clsx(
          classes.wrapper,
          hasIcon && classes.wrapper__icon,
          className,
        )}
      >
        <input className={classes.input} {...props} ref={ref} />
        {hasIcon && <TbSearch className={classes.icon} />}
      </div>
    )
  },
)
