import React, { PropsWithChildren, useEffect } from 'react'
import { TbCalendarEvent } from 'react-icons/tb'
import { calendlyCalendarUrl } from 'routes/constants'
import clsx from 'clsx'
import classes from './BookCalendly.module.scss'
import { useTrackingContext } from 'routes/components/Layout'
import Popup from 'routes/components/Shared/Popup/Popup'
import { pushGtmEvent } from 'helpers/gtm'
import { BookCalendlyProvider, useBookCalendly } from './book-calendly-provider'
import { CalendlyEventData } from './types'
import {
  fetchCalendlyData,
  isCalendlyEvent,
  isCalendlyEventScheduled,
} from './calendly-helpers'
import { Loader, Button } from 'routes/components/Shared'

export { BookCalendlyProvider }

export function BookCalendly() {
  const { showPopup, togglePopup, eventData, setEventData } = useBookCalendly()

  function handleClose() {
    // Clear event data on close
    setEventData(null)
    togglePopup()
  }

  return (
    <Popup
      onClose={handleClose}
      open={showPopup}
      className={eventData ? classes.container__small : classes.container}
    >
      {eventData ? <CalendlyScheduled eventData={eventData} /> : <Calendly />}
    </Popup>
  )
}

function Calendly() {
  return (
    <>
      <Loader className={classes.loader} />
      <BookCalendlyIframe className={classes.iframe}></BookCalendlyIframe>
    </>
  )
}

function CalendlyScheduled({ eventData }) {
  function formatDate(date) {
    const options = {
      hour: '2-digit',
      hour12: false,
      minute: '2-digit',
    }

    // @ts-expect-error TS(2769): Intl.DateTimeFormat options
    return new Intl.DateTimeFormat('en-US', options).format(date)
  }

  const startDate = new Date(eventData.start_time)
  const endDate = new Date(eventData.end_time)
  const startTime = formatDate(startDate)
  const endTime = formatDate(endDate)
  const dayMonthYear = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'full',
  }).format(startDate)

  return (
    <div className={classes.scheduledEvent}>
      <img
        src={'/images/logo--black.svg'}
        alt=''
        className={classes.app_logo}
      />
      <h1>
        <Icon className={classes.icon} /> Thanks! You are scheduled
      </h1>
      <p>A calendar invitation has been sent to your email address.</p>
      <div className={classes.eventDetails}>
        <h2>{eventData?.name}</h2>
        <p>
          {startTime} — {endTime}, {dayMonthYear}
        </p>
      </div>
    </div>
  )
}

function Icon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      viewBox='0 0 28 28'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
    >
      <rect width='28' height='28' fill='#2D7509' rx='14' />
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='m8.167 14 4.166 4.167 8.334-8.334'
      />
    </svg>
  )
}

export function BookCalendlyButton({
  children,
  className,
  category,
  isReversed,
  showIcon = true,
}: PropsWithChildren<{
  className: string
  category: string
  isReversed?: boolean
  showIcon?: boolean
}>) {
  const { togglePopup } = useBookCalendly()
  return (
    <Button
      className={clsx(classes.button, className)}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      category={category as any}
      onClick={togglePopup}
    >
      <span
        className={clsx(
          classes.button_caption,
          isReversed && classes.button_caption__reversed,
        )}
      >
        {children}
      </span>
      {showIcon && (
        <TbCalendarEvent
          className={clsx(
            classes.button_icon,
            isReversed && classes.button_icon__reversed,
          )}
        />
      )}
    </Button>
  )
}

export function BookCalendlyIframe({ className }: { className?: string }) {
  const { addTrackingToUrl } = useTrackingContext()
  const { setEventData } = useBookCalendly()

  useEffect(() => {
    window.addEventListener('message', trackCalendlyEventData)
    return () => window.removeEventListener('message', trackCalendlyEventData)
  }, [])

  async function trackCalendlyEventData(e: MessageEvent<CalendlyEventData>) {
    if (!isCalendlyEvent(e) || !isCalendlyEventScheduled(e)) {
      return
    }

    pushGtmEvent('calendly_event_scheduled')
    const payloadEventUri = e?.data?.payload?.event?.uri
    const payloadInviteeUri = e?.data?.payload?.invitee?.uri

    if (payloadEventUri) {
      const event = await fetchCalendlyData(payloadEventUri)
      setEventData(event)
    }

    if (payloadInviteeUri) {
      const user = await fetchCalendlyData(payloadInviteeUri)
      pushGtmEvent('user_identify', {
        userName: user.name,
        userEmail: user.email,
      })
    }
  }

  return (
    <iframe
      src={addTrackingToUrl(calendlyCalendarUrl)}
      allowFullScreen
      className={className}
    ></iframe>
  )
}
