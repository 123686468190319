type GmtEvents =
  | 'user_identify'
  | 'calendly_event_scheduled'
  | 'search_integration_scenario'
type GtmVariables = 'userEmail' | 'userName' | 'searchIntegrationScenario'

declare const window: Window & {
  dataLayer?: object[]
}

export function pushGtmEvent(
  event: GmtEvents,
  props: Partial<Record<GtmVariables, string>> = {},
) {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event,
      ...props,
    })
  }
}
