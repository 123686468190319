import { ReactNode } from 'react'
import clsx from 'clsx'
import classes from './Container.module.scss'

type ContainerProps = {
  children: ReactNode
  className?: string
}

export function Container({ children, className }: ContainerProps) {
  return <div className={clsx(classes.wrapper, className)}>{children}</div>
}
