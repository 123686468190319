import clsx from 'clsx'
import classes from './Content.module.css'

export function Content({
  content,
  className,
}: {
  content: string
  className?: string
}) {
  return (
    <div
      className={clsx(classes.content, className)}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}
