import React, { HTMLAttributes } from 'react'

const nobrStyle = {
  color: 'inherit',
  whiteSpace: 'nowrap',
}
export function Nobr({ children, ...props }: HTMLAttributes<HTMLSpanElement>) {
  return (
    <span style={nobrStyle} {...props}>
      {children}
    </span>
  )
}
