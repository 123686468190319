import { API_ENDPOINTS, API_VERSION_QUERY } from 'routes/constants'
import { validateResponseStatusCode } from 'routes/Integrations/helpers'

export async function fetchIntegrationOperations(integrationKey: string) {
  try {
    // Fetch data from connectors endpoint, because we store operations there
    // and we don't have separate endpoint for operations
    const res = await fetch(
      `${API_ENDPOINTS.CONNECTORS}/${integrationKey}${API_ENDPOINTS.OPERATIONS}`,
    )

    validateResponseStatusCode(
      res,
      `Integration Operations not found: ${integrationKey}`,
    )

    const operations = await res.json()

    return {
      operations,
      operations_amount: operations?.length ?? null,
    }
  } catch (e) {
    return {
      message: `Integration Operations for ${integrationKey} not found`,
      type: 'bad_request',
      key: 'not_found',
    }
  }
}

export async function fetchIntegrationSingleOperation(
  integrationKey: string,
  operationKey: string,
) {
  try {
    const res = await fetch(
      `${API_ENDPOINTS.CONNECTORS}/${integrationKey}/operations/${operationKey}${API_VERSION_QUERY}`,
    )

    validateResponseStatusCode(
      res,
      `Integration Operation not found: ${integrationKey}/${operationKey}`,
    )

    return await res.json()
  } catch (e) {
    return {
      message: `Integration Operation for ${integrationKey}/${operationKey} not found`,
      type: 'bad_request',
      key: 'not_found',
    }
  }
}
