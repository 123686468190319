import Head from 'next/head'
import { PropsWithChildren } from 'react'

function getAbsoluteUrl(url: string) {
  return `${process.env.NEXT_PUBLIC_SITE_URI}/${url}`
}

const DEFAULT_TITLE = 'AI-powered Integration Framework'
const DEFAULT_DESCRIPTION =
  "Integration platforms don't work. Scale your in-house integration development with our AI-powered framework."
const DEFAULT_OPEN_GRAPH_IMAGE = getAbsoluteUrl(
  'images/image-opengraph-202403.png',
)

export interface HeadMetatagsProps {
  title?: string
  description?: string
  openGraphImageUrl?: string
  type?: 'website' | 'article'
  canonical?: string
}

export function HeadMetatags({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  openGraphImageUrl = DEFAULT_OPEN_GRAPH_IMAGE,
  type = 'website',
  canonical,
  children,
}: PropsWithChildren<HeadMetatagsProps>) {
  const pageTitle = `${title} | Integration App`

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name='description' content={description} />
      <meta property='og:type' content={type} key='og:type' />
      <meta property='og:title' content={title} key='og:title' />
      <meta
        property='og:description'
        content={description}
        key='og:description'
      />
      <meta property='og:image' content={openGraphImageUrl} key='og:image' />
      <meta
        name='twitter:card'
        content='summary_large_image'
        key='twitter:card'
      />
      <meta
        property='twitter:domain'
        content='integration.app'
        key='twitter:domain'
      />
      <meta name='twitter:title' content={title} key='twitter:title' />
      <meta
        name='twitter:description'
        content={description}
        key='twitter:description'
      />
      <meta
        name='twitter:image'
        content={openGraphImageUrl}
        key='twitter:image'
      />

      {canonical && (
        <link
          rel='canonical'
          href={`${process.env.NEXT_PUBLIC_SITE_URI}${canonical}`}
        />
      )}
      {children}
    </Head>
  )
}

export const HeadMetatagViewport = () => (
  <meta
    name='viewport'
    content='minimum-scale=1, initial-scale=1, width=device-width'
  />
)

// How to Favicon in 2024
// The smallest setup with the most coverage
// https://evilmartians.com/chronicles/how-to-favicon-in-2021-six-files-that-fit-most-needs
export function HeadMetatagsFavicons() {
  return (
    <>
      <link rel='icon' href='/favicon/favicon.ico' sizes='32x32' />
      <link rel='icon' href='/favicon/favicon.svg' type='image/svg+xml' />
      <link rel='apple-touch-icon' href='/favicon/apple-touch-icon.png' />
    </>
  )
}
