import localFont from 'next/font/local'

const satoshi = localFont({
  src: [
    {
      path: './font-satoshi-400.woff2',
      weight: '400',
    },
    {
      path: './font-satoshi-500.woff2',
      weight: '500',
    },
  ],
  fallback: [
    'avenir',
    'montserrat',
    'corbel',
    'URW Gothic',
    'source-sans-pro',
    'sans-serif',
  ],
  adjustFontFallback: false,
  variable: '--font-satoshi',
})

const clashGrotesk = localFont({
  src: [
    {
      path: './font-clash-grotesk-400.woff2',
      weight: '400',
    },
    {
      path: './font-clash-grotesk-500.woff2',
      weight: '500',
    },
  ],
  fallback: [
    'avenir',
    'montserrat',
    'corbel',
    'URW Gothic',
    'source-sans-pro',
    'sans-serif',
  ],
  adjustFontFallback: false,
  variable: '--font-clash',
})

const logoFont = localFont({
  src: './font-logo.woff2',
  fallback: ['system-ui', 'sans-serif'],
  adjustFontFallback: false,
  variable: '--font-logo',
})

const firaCode = localFont({
  src: './font-fira-code-400.woff2',
  weight: '400',
  fallback: [
    'monaco',
    'consolas',
    'Lucida Console',
    'Andale Mono',
    'monospace',
  ],
  adjustFontFallback: false,
  variable: '--font-fira',
})

const digitalNumbers = localFont({
  src: './font-digital-numbers-400.woff2',
  weight: '400',
  fallback: [
    'monaco',
    'consolas',
    'Lucida Console',
    'Andale Mono',
    'monospace',
  ],
  adjustFontFallback: false,
  variable: '--font-digital',
})

// Export the fonts as a constant
export default {
  logoFont,
  clashGrotesk,
  satoshi,
  firaCode,
  digitalNumbers,
}
