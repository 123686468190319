import { visit } from 'unist-util-visit'

// Support <Zoom> component in markdown
//
// Use `remark-directive` to parse a custom components
// and add the necessary data to the node

export function remarkCustomZoomDirectivePlugin() {
  return function transformer(tree: Node) {
    const visitor = (node) => {
      if (node.name !== 'zoom') {
        return
      }

      // Zoom component requires a link
      // First child of the node is data in [] brackets
      // Check if it's a link
      if (node.children[0].children[0].type !== 'link') {
        return
      }

      const link = node.children[0].children[0].url
      node.children.shift()

      const data = node.data || (node.data = {})

      // Should be the name of the component in markdownArticleComponents
      data.hName = 'Zoom'

      // Props to pass to the component
      data.hProperties = { link }
    }
    visit(tree, ['containerDirective'], visitor)
  }
}
