import NextLink, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import clsx from 'clsx'
import { PropsWithChildren } from 'react'

export type NavLinkProps = LinkProps & {
  exact?: boolean
  className?: string
  activeClassName?: string
  target?: string
}

export default function NavLink({
  className,
  activeClassName,
  exact,
  href,
  children,
  target,
  ...props
}: PropsWithChildren<NavLinkProps>) {
  const { asPath } = useRouter()

  const isActive = exact
    ? asPath === href.toString()
    : asPath.startsWith(href.toString())

  return (
    <NextLink
      href={href}
      {...props}
      className={clsx(className, isActive && activeClassName)}
      target={target}
    >
      {children}
    </NextLink>
  )
}
