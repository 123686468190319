import { useState } from 'react'
import { useMediaQuery } from 'usehooks-ts'
import { TbChevronDown, TbArrowRight } from 'react-icons/tb'
import { Link } from 'routes/components/Shared'
import {
  headerNavigationData,
  HeaderNavigationSubmenuDataType,
} from './headerNavigationData'
import { HeaderNavigationDataType } from './headerNavigationData'
import clsx from 'clsx'
import classes from './HeaderStyles.module.scss'

export const HeaderNavigation = () => {
  const [subMenuActiveIndex, setSubMenuActiveIndex] = useState<number | null>(
    null,
  )
  const isTablet = useMediaQuery('(max-width: 1023px)')

  const toggleSubMenu = (index: number) => {
    if (!isTablet) return
    setSubMenuActiveIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  return (
    <nav className={classes.navigation}>
      <ul className={classes.menuList}>
        {headerNavigationData.map((item, idx) => (
          <NavigationItem
            key={idx}
            item={item}
            onClick={() => toggleSubMenu(idx)}
            isActive={subMenuActiveIndex === idx}
          />
        ))}
      </ul>
    </nav>
  )
}

const NavigationItem = ({
  item,
  onClick,
  isActive,
}: {
  item: HeaderNavigationDataType
  onClick: () => void
  isActive: boolean
}) => {
  const { name, href, isBlank, type, columns } = item

  return (
    <>
      {href ? (
        <li className={classes.menuItem}>
          <Link
            href={href}
            target={isBlank ? '_blank' : '_self'}
            className={classes.menuLink}
          >
            {name}
          </Link>
        </li>
      ) : (
        <li
          className={clsx(
            classes.menuItem,
            classes.menuItem__dropdown,
            isActive && classes.menuItem__open,
          )}
        >
          <span className={classes.menuTitle} onClick={onClick}>
            <TbChevronDown
              className={clsx(classes.icon, classes.icon__chevron)}
            />
            {name}
          </span>
          <SubMenu
            submenuData={columns as HeaderNavigationSubmenuDataType[]}
            type={type as string}
          />
        </li>
      )}
    </>
  )
}

const SubMenu = ({
  submenuData,
  type,
}: {
  submenuData: HeaderNavigationSubmenuDataType[]
  type: string
}) => {
  return (
    <div className={clsx(classes.submenu, classes[`submenu${type}`])}>
      <div className={classes.submenuWrapper}>
        {submenuData.map(({ caption, links }, idx) => (
          <div className={classes.submenuColumn} key={idx}>
            <div>
              {caption && (
                <span className={classes.submenuCaption}>{caption}</span>
              )}
              <ul className={classes.submenuList}>
                {links.map(({ title, href, isBlank }, idx) => (
                  <li className={classes.submenuItem} key={idx}>
                    <Link
                      href={href}
                      target={isBlank ? '_blank' : '_self'}
                      className={classes.submenuLink}
                    >
                      {title}
                      <TbArrowRight
                        className={clsx(classes.icon, classes.icon__arrow)}
                      />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
