import { Connector } from '@integration-app/sdk'
import { API_ENDPOINTS } from 'routes/constants'

async function getConnectorData(path: string) {
  const res = await fetch(`${API_ENDPOINTS.CONNECTORS}/${path}`)

  if (res.status !== 200) {
    throw new Error(`Connector not found: ${path}`)
  }

  return await res.json()
}

export async function fetchConnectorOperations(integration: Connector) {
  if (!integration.hasOperations) {
    return []
  }
  return getConnectorData(`${integration.id}${API_ENDPOINTS.OPERATIONS}`)
}

export async function fetchConnectorData(integration: Connector) {
  if (!integration.hasData) {
    return []
  }
  return getConnectorData(`${integration.id}${API_ENDPOINTS.DATA_COLLECTIONS}`)
}

export async function fetchSimilarConnectors(integration: Connector) {
  const similarConnectors = await getConnectorData(
    `${integration.id}${API_ENDPOINTS.SIMILAR}`,
  )

  await Promise.all(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (similarConnectors ?? []).map(async (connector: any) => {
      const res = await fetch(
        `${API_ENDPOINTS.CONNECTORS}/${connector.id}${API_ENDPOINTS.UDM}`,
      )
      connector.udm = await res.json()
    }),
  )

  return similarConnectors
}
