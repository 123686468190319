import { useState, useEffect } from 'react'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { useMediaQuery } from 'usehooks-ts'
import { Container } from 'routes/components/Layout'
import { HeaderLogo } from './HeaderLogo'
import { HeaderNavigation } from './HeaderNavigation'
import { HeaderActions } from './HeaderActions'
import clsx from 'clsx'
import classes from './HeaderStyles.module.scss'

export const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false)

  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const stickyHeaderTest = useFeatureFlagVariantKey('sticky-header-test')

  // to remove classes for mobile menu on <body> and <header>
  // if device width changes to desktop
  useEffect(() => {
    if (isDesktop) {
      setShowMobileMenu(false)
    }
  }, [isDesktop])

  const toggleMobileMenu = () => {
    setShowMobileMenu((prev) => !prev)
  }

  return (
    <header
      className={clsx(
        classes.header,
        stickyHeaderTest === 'test' && classes.header__sticky,
        showMobileMenu && classes.header__mobile,
      )}
    >
      <Container className={classes.wrapper}>
        <HeaderLogo
          onClick={toggleMobileMenu}
          showMobileMenu={showMobileMenu}
        />

        <HeaderNavigation />

        <HeaderActions />
      </Container>
    </header>
  )
}
