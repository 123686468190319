import qs from 'qs'
import { CalendlyEventData } from './types'

export async function fetchCalendlyData(uri: string) {
  const query = qs.stringify({
    uri,
  })
  const url = `/utils/calendly?${query}`
  return fetch(url)
    .then((r) => r.json())
    .then((r) => r.resource)
}

export function isCalendlyEvent(e: MessageEvent<CalendlyEventData>) {
  return (
    e.origin === 'https://calendly.com' &&
    e.data?.event &&
    e.data?.event?.indexOf('calendly.') === 0
  )
}

export function isCalendlyEventScheduled(e: MessageEvent<CalendlyEventData>) {
  return e.data.event === 'calendly.event_scheduled'
}
