import { Children, PropsWithChildren, cloneElement } from 'react'
import { Balancer } from 'react-wrap-balancer'
import clsx from 'clsx'
import classes from './Hgroup.module.css'

export function Hgroup({
  children,
  align = 'center',
  className,
}: PropsWithChildren<{
  align?: 'left' | 'center' | 'right'
  className?: string
}>) {
  return (
    <hgroup
      className={clsx(
        classes.hgroup,
        align === 'left' && classes.hgroup__left,
        align === 'center' && classes.hgroup__center,
        align === 'right' && classes.hgroup__right,
        className,
      )}
    >
      <BalancerWrapper>{children}</BalancerWrapper>
    </hgroup>
  )
}

function BalancerWrapper({ children }) {
  function hasNestedComponents(children) {
    return Children.toArray(children).some((child) => {
      return typeof child === 'object'
    })
  }

  return Children.map(children, (child) => {
    if (
      ['h1', 'h2', 'h3', 'h4', 'p'].includes(child.type) &&
      !hasNestedComponents(child.props.children)
    ) {
      return cloneElement(child, {
        children: <Balancer>{child.props.children}</Balancer>,
      })
    }
    return child
  })
}
