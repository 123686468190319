import { PropsWithChildren, useEffect } from 'react'
import { Router } from 'next/router'
import posthog from 'posthog-js'
import { PostHogProvider as PHProvider } from 'posthog-js/react'

export function PostHogProvider({ children }: PropsWithChildren) {
  useEffect(() => {
    const postHogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY
    if (postHogKey) {
      posthog.init(postHogKey, {
        api_host:
          process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
        person_profiles: 'identified_only',
        // Enable debug mode in development
        loaded: (posthog) => {
          if (process.env.DEBUG_POSTHOG === 'true') posthog.debug()
        },
      })
    }

    const handleRouteChange = () => posthog?.capture('$pageview')

    Router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return <PHProvider client={posthog}>{children}</PHProvider>
}
