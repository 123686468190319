import clsx from 'clsx'

export function JsonViewer({
  json,
  title,
  className,
}: {
  json: Record<string, any>
  title?: string
  className?: string
}) {
  if (process.env.NODE_ENV !== 'development') {
    return null
  }
  return (
    <details className={clsx('my-8', className)}>
      <summary>{title || 'JSON'}</summary>
      <pre className={'max-w-full overflow-x-auto whitespace-pre-wrap'}>
        <code>{JSON.stringify(json, undefined, 2)}</code>
      </pre>
    </details>
  )
}
