/* eslint-disable @typescript-eslint/no-explicit-any */
import { API_ENDPOINTS } from 'routes/constants'
import { CategoryCompactType } from 'routes/Integrations/helpers/types'
import { getTotalIntegrations } from 'routes/Integrations/helpers'

const DEFAULT_SELECTED_CATEGORY = 'All'

const CATEGORIES_ORDER = [
  DEFAULT_SELECTED_CATEGORY,
  'CRM',
  'Marketing Automation',
  'Project Management',
  'Accounting',
  'HRIS',
]

export async function getAllCategories(): Promise<CategoryCompactType[]> {
  const response = await fetch(API_ENDPOINTS.INTEGRATION_CATEGORIES)

  const { categories: rawCategories = [] } = await response.json()

  const totalApps = await getTotalIntegrations()

  const safeCategories = getCategoriesWithAllCategory(rawCategories, totalApps)

  return orderCategories(safeCategories)
}

function getCategoriesWithAllCategory(
  categories: CategoryCompactType[],
  totalValue: number,
) {
  const allCategories: CategoryCompactType[] = [
    {
      count: totalValue,
      category: null,
    },
  ]

  categories.forEach((category) => {
    // All non-categorized apps are included in the All category
    if (category.category !== 'Uncategorized') {
      allCategories.push(category)
    }
  })

  return allCategories
}

function orderCategories(categories: CategoryCompactType[]) {
  return [...categories].sort((a, b) => {
    const indexA = CATEGORIES_ORDER.indexOf(a.category ?? '')
    const indexB = CATEGORIES_ORDER.indexOf(b.category ?? '')

    // If one of the categories is DEFAULT, it should be at the top
    if (getReadableCategoryName(a.category) === DEFAULT_SELECTED_CATEGORY) {
      return -1
    }
    if (getReadableCategoryName(b.category) === DEFAULT_SELECTED_CATEGORY) {
      return 1
    }

    // If any of the categories is in the list, it should be sorted by the list
    if (indexA >= 0 || indexB >= 0) {
      if (indexA === indexB) return 0
      if (indexA === -1) return 1
      if (indexB === -1) return -1
      return indexA < indexB ? -1 : 1
    }

    // If none of the categories is in the list, sort by count
    return a.count === b.count ? 0 : a.count < b.count ? 1 : -1
  })
}

export function getReadableCategoryName(
  category?: CategoryCompactType['category'],
) {
  return category === null || category === undefined
    ? DEFAULT_SELECTED_CATEGORY
    : category
}

export function isEqualCategories(
  categoryA: string | null | undefined,
  categoryB: string | null | undefined,
) {
  if (typeof categoryA === typeof categoryB) {
    return categoryA === categoryB
  }

  return false
}
