import { TbMenu2, TbX } from 'react-icons/tb'
import { Button, LogoBlock } from 'routes/components/Shared'
import clsx from 'clsx'
import classes from './HeaderStyles.module.scss'

export const HeaderLogo = ({
  onClick,
  showMobileMenu,
}: {
  onClick: () => void
  showMobileMenu: boolean
}) => {
  return (
    <div className={classes.logoBlock}>
      <Button
        className={clsx(classes.button, classes.button__menu)}
        onClick={onClick}
        aria-label='Menu'
      >
        {showMobileMenu ? (
          <TbX className={clsx(classes.icon, classes.icon__close)} />
        ) : (
          <TbMenu2 className={clsx(classes.icon, classes.icon__menu)} />
        )}
      </Button>

      <LogoBlock className={classes.logo} />
    </div>
  )
}
