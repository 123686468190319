import clsx from 'clsx'
import ReactMarkdown from 'react-markdown'
import rehypeSlug from 'rehype-slug'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import rehypeUnwrapImages from 'rehype-unwrap-images'
import remarkDirective from 'remark-directive'
import { remarkCustomHighlightDirectivePlugin } from 'helpers/markdown/customHighlightDirectivePlugin'
import { remarkCustomZoomDirectivePlugin } from 'helpers/markdown/customZoomDirectivePlugin'

import { HEADERS_IN_TOC } from 'routes/constants'
import {
  ArticleTocRootUl,
  ArticleActiveHeaderIdProvider,
  useArticleActiveHeaderId,
} from 'routes/components/Layout'

import { ImageFromStrapi } from './components/Image'
import { Highlight } from './components/Highlight'
import { ZoomImage } from './components/ZoomImage/ZoomImage'
import { PreTag } from './components/PreTag'
import { Table } from './components/Table'
import { LinkTag } from './components/LinkTag'
import classes from './Markdown.module.scss'
import { HTMLAttributes } from 'react'

const remarkPlugins = [
  remarkGfm,
  remarkDirective,
  remarkCustomHighlightDirectivePlugin,
  remarkCustomZoomDirectivePlugin,
]
const rehypePlugins = [rehypeSlug, rehypeUnwrapImages, rehypeRaw]

const markdownArticleComponents = {
  img: ImageFromStrapi,
  Highlight: Highlight,
  highlight: Highlight,
  Zoom: ZoomImage,
  zoom: ZoomImage,
  pre: PreTag,
  table: Table,
  a: LinkTag,
}

export function Markdown({ children }) {
  return (
    <ReactMarkdown remarkPlugins={remarkPlugins} rehypePlugins={rehypePlugins}>
      {children}
    </ReactMarkdown>
  )
}

export function MarkdownArticle({ children }) {
  return (
    <ReactMarkdown
      components={markdownArticleComponents}
      remarkPlugins={remarkPlugins}
      rehypePlugins={rehypePlugins}
    >
      {children}
    </ReactMarkdown>
  )
}

const allowedElementsInToc = HEADERS_IN_TOC

function filterAllowedElementsInToc(element, _idx, parent) {
  return (
    allowedElementsInToc.includes(element.tagName) ||
    allowedElementsInToc.includes(parent.tagName)
  )
}

function TocListItemComponent({ node, children, id = '' }) {
  const activeHeaderId = useArticleActiveHeaderId()
  return (
    <li data-level={node.tagName}>
      <a href={`#${id}`} aria-selected={activeHeaderId === id}>
        {children}
      </a>
    </li>
  )
}

const TocComponents = {}
allowedElementsInToc.forEach((x) => {
  TocComponents[x] = TocListItemComponent
})

export function MarkdownArticleToc({ children }) {
  const headersOnlyText = normalizeHeaders(getHeadersFromMarkdown(children))

  if (!headersOnlyText) {
    return null
  }

  return (
    <ArticleActiveHeaderIdProvider>
      <ArticleTocRootUl>
        <ReactMarkdown
          rehypePlugins={rehypePlugins}
          allowElement={filterAllowedElementsInToc}
          components={TocComponents}
        >
          {children}
        </ReactMarkdown>
      </ArticleTocRootUl>
    </ArticleActiveHeaderIdProvider>
  )
}

export function getHeadersFromMarkdown(text: string) {
  return text
    .split('\n')
    .filter((x) => x.startsWith('#'))
    .join('\n')
}

export function normalizeHeaders(text: string) {
  const textArray = text.split('\n')

  const highersHeaderLevel = textArray.reduce((acc, x) => {
    return Math.min(acc, x.split(' ')[0].length)
  }, 6)

  if (highersHeaderLevel > 2) {
    const diff = highersHeaderLevel - 2
    return textArray.map((x) => x.slice(diff)).join('\n')
  }

  return text
}

export function StyledMarkdownWrapper({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={clsx(classes.styledTypography, className)} {...props}>
      {children}
    </div>
  )
}
