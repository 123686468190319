import React, { ButtonHTMLAttributes } from 'react'
import clsx from 'clsx'

export const Button = ({
  children,
  htmlType = 'button',
  category = '',
  className = '',
  onClick,
  disabled = false,
  ...attrs
}: Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'> & {
  htmlType?: HTMLButtonElement['type']
  category?: 'primary' | 'secondary' | ''
}) => {
  const categories = {
    primary: 'btn--primary',
    secondary: 'btn--secondary',
  }

  return (
    <button
      type={htmlType}
      className={clsx(
        'btn',
        categories[category],
        className,
        disabled && 'btn--disabled',
      )}
      onClick={!disabled ? onClick : () => {}}
      disabled={disabled}
      {...attrs}
    >
      <span className='btn__content'>{children}</span>
    </button>
  )
}
