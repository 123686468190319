import React from 'react'

type EventType =
  | React.MouseEvent<any, any>
  | React.ChangeEvent<any>
  | React.KeyboardEvent<any>
  | React.SyntheticEvent<any>
  | TouchEvent
  | MouseEvent
  | KeyboardEvent

export type EventHandlerFunctionType = (event?: EventType) => any

export default function useEventStopPropagation(
  fn: EventHandlerFunctionType | true,
) {
  if (!fn) return undefined

  return function (event: EventType) {
    const isClickOnLink = !!(event.target as HTMLElement).closest('a')

    // If click on link, don't stop default behavior
    !isClickOnLink && event?.preventDefault?.()

    // Stop event propagation
    event?.stopPropagation?.()

    // Stop all other listeners from being called
    ;(event as React.SyntheticEvent)?.nativeEvent?.stopImmediatePropagation?.()

    if (typeof fn === 'function') fn?.(event)
  }
}
