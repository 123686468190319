const MIN_STRING_LENGTH = 200

export function truncate(
  description: string | undefined,
  length: number = MIN_STRING_LENGTH,
) {
  if (!description || description.length < length) {
    return description
  }

  const getPositionOf = (str, substr) => {
    const pos = str.indexOf(substr, length)

    if (pos === -1) {
      return Infinity
    }

    return pos
  }

  const positionOfNewLine = getPositionOf(description, `\n`)
  const positionOfNewPhrase = getPositionOf(description, '. ')

  const positionToCut = Math.min(positionOfNewLine, positionOfNewPhrase)

  const shortDescription =
    positionToCut === Infinity
      ? description
      : description.slice(0, positionToCut)

  const lastChar = shortDescription[shortDescription.length - 1]
  const postFix = lastChar === '.' ? '..' : '...'
  return shortDescription + postFix // make it `...` in any case
}
