import { ArticleType } from 'helpers/strapi/types'
import classes from './ArticleAuthor.module.css'

export function ArticleAuthor({ author }: { author: ArticleType['author'] }) {
  if (!author) return null
  return (
    <span className={classes.wrapper}>
      <ArticleAuthorIcon />
      <span>{author.name || author.username}</span>
    </span>
  )
}

function ArticleAuthorIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='none'
      className={classes.icon}
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeOpacity='.8'
        strokeWidth='2'
        d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2M12 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z'
      />
    </svg>
  )
}
