/* eslint-disable @typescript-eslint/no-explicit-any */
import { getUrlWithQuery } from 'helpers/querystring'
import { Connector } from '@integration-app/sdk'
import { INTEGRATIONS_PER_PAGE, API_ENDPOINTS, LINKS } from 'routes/constants'
import { IntegrationCompactType } from 'routes/Integrations/helpers/types'
import { fetchIntegrationDataCollections } from 'routes/Integrations/helpers/collections'
import { fetchIntegrationOperations } from './operations'
import {
  fetchConnectorData,
  fetchConnectorOperations,
  fetchSimilarConnectors,
} from 'routes/Integrations/helpers/connectors'

export * from './categories'

function getOffsetBasedOnPage(page?: string | number) {
  return Number(page ?? 1) * INTEGRATIONS_PER_PAGE - INTEGRATIONS_PER_PAGE
}

export async function fetchIntegrations({
  page,
  search,
  category,
  filter,
  signal,
}: {
  page?: string | number
  search?: string
  category?: string
  filter?: string[]
  signal?: AbortSignal
} = {}) {
  const offset = getOffsetBasedOnPage(page)

  const query = {
    offset,
    limit: INTEGRATIONS_PER_PAGE,
    search: search?.toLowerCase(),
    category,
  }

  if (filter?.length) {
    query['filter'] = filter
  }

  const response = await fetch(
    getUrlWithQuery(API_ENDPOINTS.INTEGRATIONS, query),
  )

  const { apps, total } = await response.json()

  const integrations = (apps as Array<Record<string, any>>).map(
    mapIntegrationFromResponse,
  )

  return replaceUndefinedWithNullRecursively({
    integrations,
    totalAmount: total,
    signal,
  })
}

function mapIntegrationFromResponse(
  item: Record<string, any>,
): IntegrationCompactType {
  return {
    key: item.key,
    name: item.name,
    logoUri: item.logoUri,
    appUri: item?.appUri,
    categories: item?.categories ?? [],
    onDemand: item?.onDemand,
    hasAuth: item?.hasAuth,
    hasData: item?.hasData,
  }
}

export async function getTop50IntegrationsKeys(): Promise<string[]> {
  // Fetch data from external API
  const res = await fetch(`${API_ENDPOINTS.INTEGRATIONS}?limit=50`)
  return ((await res.json())?.apps ?? []).map((c: any) => c.key) as string[]
}

export async function getTotalIntegrations() {
  const response = await fetch(`${API_ENDPOINTS.INTEGRATIONS}?limit=1`)

  const { total } = await response.json()

  return total
}

export async function fetchIntegration(integrationKey: string) {
  const [integrationData, dataCollectionsData, operationsData, udms] =
    await Promise.all([
      fetchIntegrationBasicInfo(integrationKey),
      fetchIntegrationDataCollections(integrationKey),
      fetchIntegrationOperations(integrationKey),
      fetchIntegrationUdm(integrationKey),
    ])

  return {
    ...checkValidData(integrationData),
    ...checkValidData(dataCollectionsData),
    ...checkValidData(operationsData),
    udms: checkValidData(udms) || {},
  }
}

async function fetchIntegrationBasicInfo(integrationKey: string) {
  try {
    const res = await fetch(`${API_ENDPOINTS.INTEGRATIONS}/${integrationKey}`)

    validateResponseStatusCode(res, `Integration not found: ${integrationKey}`)

    return await res.json()
  } catch (e) {
    return {
      message: `Integration ${integrationKey} not found`,
      type: 'bad_request',
      key: 'not_found',
    }
  }
}

async function fetchIntegrationUdm(integrationKey: string) {
  try {
    // Fetch data from connectors endpoint, because we store data collections there
    // and we don't have separate endpoint for data collections
    const res = await fetch(
      `${API_ENDPOINTS.CONNECTORS}/${integrationKey}${API_ENDPOINTS.UDM}`,
    )

    validateResponseStatusCode(
      res,
      `Universal Data Model not found: ${integrationKey}`,
    )

    return await res.json()
  } catch (e) {
    return {
      message: `Universal Data Model for ${integrationKey} not found`,
      type: 'bad_request',
      key: 'not_found',
    }
  }
}

export async function fetchIntegrationGettingStarted(integrationKey: string) {
  try {
    const res = await fetch(
      `${API_ENDPOINTS.INTEGRATIONS}/${integrationKey}/readme`,
    )

    validateResponseStatusCode(res, `Integration not found: ${integrationKey}`)

    return (await res.json())?.readme
  } catch (e) {
    return {
      message: `Integration ${integrationKey} not found`,
      type: 'bad_request',
      key: 'not_found',
    }
  }
}

export function getIntegrationUrl(
  integration: string,
  dataQuery?: string,
  dataKey?: string,
  path?: string,
) {
  return [LINKS.INTEGRATIONS, integration, dataQuery, dataKey, path]
    .filter(Boolean)
    .join('/')
}

export async function fetchConnector(integrationKey: string) {
  try {
    const res = await fetch(`${API_ENDPOINTS.CONNECTORS}/${integrationKey}`)

    validateResponseStatusCode(res, `Connector not found: ${integrationKey}`)

    const connector = await res.json()

    return fetchConnectorAdditionalDetails(connector)
  } catch (e) {
    return {
      message: `Connector ${integrationKey} not found`,
      type: 'bad_request',
      key: 'not_found',
    }
  }
}

async function fetchConnectorAdditionalDetails(connector: Connector) {
  const [connectorOperations, connectorData, similarConnectors] =
    await Promise.all([
      fetchConnectorOperations(connector),
      fetchConnectorData(connector),
      fetchSimilarConnectors(connector),
    ])

  return {
    connector,
    connectorOperations,
    connectorData,
    similarConnectors,
  }
}

export function validateResponseStatusCode(
  res: Response,
  errorMessage: string,
) {
  if (res.status !== 200 && res.status !== 400) {
    throw new Error(errorMessage)
  }
}

export function checkValidData(data: any) {
  if (data.key === 'not_found') {
    console.log(data.message)
  } else {
    return data
  }
}

function replaceUndefinedWithNullRecursively(obj: Record<string, any>) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [
          key,
          value.map((item) =>
            typeof item === 'object' && item !== null
              ? replaceUndefinedWithNullRecursively(item)
              : item ?? null,
          ),
        ]
      } else if (typeof value === 'object' && value !== null) {
        return [key, replaceUndefinedWithNullRecursively(value)]
      }

      return [key, value ?? null]
    }),
  )
}
