export const LINKS = {
  ABOUT_US: '/about-us',
  ARTICLES: '/articles',
  CASE_STUDIES: '/articles/case-studies',
  CHANGELOG: '/articles/changelog',
  CONNECTOR_BUILDER: '/features/connector-builder',
  INTEGRATION_UX: '/features/integration-ux',
  UNIVERSAL_INTEGRATIONS: '/features/universal-integrations',
  HOW_WE_ARE_DIFFERENT: '/how-we-are-different',
  HOW_WE_USE_AI: '/how-we-use-ai',
  PRICING: '/pricing',
  INTEGRATIONS: '/integrations',
  SOLUTIONS: {
    HEAD: '/solutions',
    ONE_CLICK_INTEGRATIONS: '/solutions/one-click-integrations',
    IMPORT_FROM_EXTERNAL_APP: '/solutions/import-from-external-app',
    PUSH_DATA_TO_EXTERNAL_APPS: '/solutions/push-data-to-external-apps',
    IN_APP_WORKFLOW_BUILDER: '/solutions/in-app-workflow-builder',
    MIGRATION_FROM_OTHER_APPS: '/solutions/migration',
    JUST_IN_TIME_INTEGRATIONS: '/solutions/just-in-time-integrations',
    REALTIME_DATA_SYNC: '/solutions/realtime-data-sync',
    DATA_INTEGRATION_PLATFORMS: '/solutions/data-integration-tools',
    WORKFLOW_AUTOMATION_TOOLS: '/solutions/workflow-automation-tools',
    AI_AGENTS: '/solutions/ai-agents',
  },
  SCENARIOS: '/scenarios',
  EVALUATION_CRITERIA: '/evaluation-criteria',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
}

export const YOUTUBE_DEMO_VIDEO_ID = 'fRZrCEj7Vws'

export const CONSOLE_LINK = 'https://console.integration.app'
export const CONSOLE_LOGIN_LINK = 'https://console.integration.app/login'
export const DOCS_LINK = 'https://console.integration.app/docs/overview'
export const CONNECTOR_DOCS_LINK =
  'https://console.integration.app/docs/engine/external-apps/connectors'
export const SCENARIOS_DOCS_LINK =
  'https://console.integration.app/docs/overview/building-integrations'
export const REST_API_LINK =
  'https://console.integration.app/ref/rest-api/index.html'
export const JS_SDK_LINK = 'https://console.integration.app/ref/sdk/index.html'
export const REACT_SDK_LINK =
  'https://console.integration.app/ref/react/index.html'

export const IUBENDA_POLICY_URL =
  'https://www.iubenda.com/api/privacy-policy/16049587/no-markup'
export const IUBENDS_TERMS_AND_CONDITIONS_URL =
  'https://www.iubenda.com/api/terms-and-conditions/16049587/no-markup'
export const G2_INTEGRATION_APP_URL = 'https://g2.com/products/integration-app'
export const LINKEDIN_URL = 'https://www.linkedin.com/company/integration-app/'
export const ASHBY_JOBS_URL = 'https://jobs.ashbyhq.com/integrationapp'

export const BASE_API_URL = 'https://api.integration.app'

export const API_VERSION_QUERY = '?version=latest'

export const API_ENDPOINTS = {
  CONNECTORS: getApiEndpointFullUrl('/connectors'),
  INTEGRATIONS: getApiEndpointFullUrl('/integration-network/apps'),
  INTEGRATION_CATEGORIES: getApiEndpointFullUrl(
    '/integration-network/categories',
  ),
  SCENARIO_TEMPLATES: getApiEndpointFullUrl('/scenario-templates'),
  DATA_COLLECTIONS: getApiEndpointWithVersionQuery('/data'),
  OPERATIONS: getApiEndpointWithVersionQuery('/operations'),
  RELATED_COLLECTIONS: getApiEndpointWithVersionQuery('/related-collections'),
  SIMILAR: getApiEndpointWithVersionQuery('/similar'),
  UDM: getApiEndpointWithVersionQuery('/udm'),
}

export const INTEGRATION_QUERIES = {
  GETTING_STARTED: 'getting-started',
  DATA_COLLECTIONS: 'data-collections',
  OPERATIONS: 'operations',
}

function getApiEndpointFullUrl(endpoint: string) {
  return `${BASE_API_URL}${endpoint}`
}

function getApiEndpointWithVersionQuery(endpoint: string) {
  return `${endpoint}${API_VERSION_QUERY}`
}

export const HIDDEN_CATEGORIES = ['pages']

export const HEADERS_IN_TOC = ['h2', 'h3']

export const calendlyCalendarUrl =
  'https://calendly.com/d/crk8-hc5-85p/integration-app-intro-call?hide_gdpr_banner=1&embed_domain=calendly-embed.com&embed_type=PopupText&hide_event_type_details=1'

const MIN_IN_SEC = 60
const HOUR_IN_SEC = MIN_IN_SEC * 60

export const ARTICLE_REVALIDATE_TIME = 1 // sec
export const PAGE_REVALIDATE_TIME = MIN_IN_SEC // sec
export const BLOG_REVALIDATE_TIME = 30 * MIN_IN_SEC
export const CATEGORY_REVALIDATE_TIME = HOUR_IN_SEC
export const SCENARIO_TEMPLATES_REVALIDATE_TIME = 6 * HOUR_IN_SEC
export const INTEGRATIONS_REVALIDATE_TIME = 6 * HOUR_IN_SEC
export const SOLUTIONS_REVALIDATE_TIME = 24 * HOUR_IN_SEC

export const INTEGRATIONS_PER_PAGE = 25
