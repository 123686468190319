import Prism from 'prismjs'
import 'prismjs/themes/prism-twilight.css'
import 'prismjs/plugins/line-numbers/prism-line-numbers'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-yaml'
import 'prismjs/components/prism-http'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-tsx'
import clsx from 'clsx'
import { useEffect, useRef } from 'react'

Prism.manual = true

export function PreTag({ children, node }) {
  const ref = useRef()
  useEffect(() => {
    if (!ref.current) return
    Prism.highlightAllUnder(ref.current)
  }, [ref.current])

  return (
    // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
    <div ref={ref} className={clsx('Code', 'line-numbers')}>
      <pre tabIndex={-1} {...(node?.properties ?? {})}>
        {children}
      </pre>
    </div>
  )
}
