import clsx from 'clsx'
import { LINKS } from 'routes/constants'
import { ArticleMinimalDataType } from 'helpers/strapi/types'
import { truncate } from 'helpers/truncate'
import { ArticlePublishDate } from 'routes/components/Layout'
import NavLink from 'routes/components/Shared/Link/NavLink'
import classes from './ArticleRecentPost.module.css'

export function ArticleRecentPosts({
  posts,
}: {
  posts: ArticleMinimalDataType[]
}) {
  if (!posts?.length) {
    return null
  }
  return (
    <>
      <h3 className={clsx('likeH4', classes.relatedPostSectionHeader)}>
        Recent posts
      </h3>

      <ul className={classes.relatedPostsList}>
        {posts.map((article) => (
          <ArticlePostBlock {...article} key={article.slug} />
        ))}
      </ul>
    </>
  )
}

function ArticlePostBlock({
  title,
  description,
  date,
  slug,
  category,
}: ArticleMinimalDataType) {
  const href = `${LINKS.ARTICLES}/${category?.slug}/${slug}`

  return (
    <li className={classes.relatedPost}>
      <h3 className={clsx('likeH6', classes.relatedPost_title)}>
        <NavLink href={href} className={classes.relatedPost_link}>
          {title}
        </NavLink>
      </h3>
      <p className={classes.relatedPost_description}>
        {truncate(description, 70)}
      </p>
      <div className={classes.relatedPost_info}>
        {date && <ArticlePublishDate textOnly>{date}</ArticlePublishDate>}
        {/*{timeToRead && <ArticleTimeToRead>{timeToRead}</ArticleTimeToRead>}*/}
      </div>
    </li>
  )
}
