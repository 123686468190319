import { CONSOLE_LOGIN_LINK } from 'routes/constants'
import { BookCalendlyButton, Link } from 'routes/components/Shared'
import clsx from 'clsx'
import classes from './HeaderStyles.module.scss'

export const HeaderActions = () => {
  return (
    <div className={classes.actions}>
      <BookCalendlyButton
        className={clsx(classes.button, classes.button__book)}
        category={'primary'}
      >
        Schedule Demo
      </BookCalendlyButton>

      <Link
        className={clsx(classes.button, classes.button__sign)}
        type={'secondary'}
        href={CONSOLE_LOGIN_LINK}
        target='_blank'
      >
        Log In / Sign Up
      </Link>
    </div>
  )
}
