import {
  ArticleMinimalDataType,
  ArticleType,
  CategoryType,
  ImageMediaDataType,
  ImageSetType,
} from 'helpers/strapi/types'

export function getStrapiURL(path = '') {
  return `${
    process.env.NEXT_PUBLIC_STRAPI_API_URL || 'http://localhost:1337'
  }${path}`
}

export function getStrapiMedia(url: string | null) {
  if (url == null) {
    return null
  }

  // Return the full URL if the media is hosted on an external provider
  if (url.startsWith('http') || url.startsWith('//')) {
    return url
  }

  // Otherwise prepend the URL path with the Strapi URL
  return `${getStrapiURL()}${url}`
}

export function normalizeStrapiData(
  data: any,
  schema: Record<string, 'oneToMany' | 'oneToOne' | 'image'>,
) {
  const result = { ...data }
  Object.entries(schema).forEach(([key, type]) => {
    if (!result[key] || !result[key]?.data) {
      result[key] = null
      return
    }
    if (type === 'oneToMany') {
      result[key] = normalizeOneToManyRelation(result[key])
    } else if (type === 'oneToOne') {
      result[key] = normalizeOneToOneRelation(result[key])
    } else if (type === 'image') {
      result[key] = normalizeImageMediaObject(result[key])
    }
  })

  return result
}

function normalizeImageMediaObject(data: any) {
  return getImageMediaData(data.data.attributes)
}

function getImageMediaData(data: any): ImageMediaDataType {
  const result: any = {}

  result.thumbnail = getImageSet(data?.formats?.thumbnail)
  result.small = getImageSet(data?.formats?.small)
  result.medium = getImageSet(data?.formats?.medium)
  result.large = getImageSet(data?.formats?.large)
  result.original = getImageSet(data)

  return result
}

function getImageSet(data: any): ImageSetType {
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'ImageSetTyp... Remove this comment to see the full error message
  if (!data) return null
  return {
    width: data?.width,
    height: data?.height,
    // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    url: getStrapiMedia(data?.url),
  }
}

function normalizeOneToOneRelation(data: any) {
  return data.data.attributes ?? null
}

function normalizeOneToManyRelation(data: any) {
  return (data.data ?? []).map((item: any) => item.attributes)
}

export function normalizeArticleData(data: any): ArticleType {
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'ArticleType... Remove this comment to see the full error message
  if (!data) return null
  return normalizeStrapiData(data, {
    tags: 'oneToMany',
    category: 'oneToOne',
    author: 'oneToOne',
    createdBy: 'oneToOne',
    cover: 'image',
    seo_image: 'image',
  })
}

export function getMinimalArticleData(
  article: ArticleType,
): ArticleMinimalDataType {
  return {
    title: article.title,
    slug: article.slug,
    description: article.description,
    date: article.date,
    category: article.category,
  }
}

export function normalizeCategoryData(data: any): CategoryType {
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'CategoryTyp... Remove this comment to see the full error message
  if (!data) return null
  const fields = ['name', 'slug', 'description']

  return fields.reduce((acc: any, field: string) => {
    acc[field] = data[field] ?? null
    return acc
  }, {})
}

export function getCoverImage(
  cover: ImageMediaDataType,
  size: keyof ImageMediaDataType = 'large',
) {
  if (!cover) return null

  // If image in requested size doesn't exist
  // it means that it's smaller than original image.
  return cover?.[size]?.url ?? cover?.original?.url
}
