import classes from './ArticleTags.module.css'
import { TagType } from 'helpers/strapi/types'
import NavLink from 'routes/components/Shared/Link/NavLink'
import { LINKS } from 'routes/constants'

export function ArticleTags({ tags }: { tags: TagType[] }) {
  if (!tags?.length) return null

  return (
    <ul className={classes.tags}>
      {tags.map((tag) => (
        <li key={tag.slug}>
          <NavLink href={`${LINKS.ARTICLES}/${tag.slug}`}>{tag.name}</NavLink>
        </li>
      ))}
    </ul>
  )
}
